@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');


@keyframes shine {
    0% {
        transform: translateX(-150%);
    }

    100% {
        transform: translateX(150%);
    }
}

.animate-shine {
    animation: shine 2s infinite;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

/* Hide scrollbar for IE and Edge */
.scrollbar-hidden {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}